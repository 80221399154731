<script setup lang="ts">
import CheckSelectorItem, {
  type ICheckSelectorItemProps,
} from "@/components/CheckSelector/CheckSelectorItem.vue";
import { useRoute } from "vue-router";
import type { QueryType } from "@/shared/utils/types";

const checkNavItems: Omit<ICheckSelectorItemProps, "selectedQueryType">[] = [
  {
    title: "Основной поиск",
    queryType: "universal-search",
    to: { name: "universal-search-check" },
  },
  {
    title: "Расширенный поиск",
    queryType: "advanced-search",
    to: { name: "advanced-search-check" },
  },
];
const route = useRoute();
const selectedQueryType = computed(
  () => (route.meta.queryType as QueryType) ?? "universal-search",
);
</script>

<template>
  <div>
    <div :class="$style.buttons">
      <CheckSelectorItem
        v-for="item in checkNavItems"
        :key="item.queryType"
        :to="item.to"
        :title="item.title"
        :query-type="item.queryType"
        :selected-query-type="selectedQueryType"
      />
    </div>
  </div>
</template>

<style module lang="scss">
.buttons {
  @include flex(normal, center);
}
</style>
