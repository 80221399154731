<script setup lang="ts">
import type { INavLink } from "@/shared/utils/types";
import { IrbisLink, LinkSkin } from "@ainform/irbis-ui-storybook";

interface IProps {
  listClass?: string;
  listItemClass?: string;
  navItems: INavLink[];
}
const props = defineProps<IProps>();
const styles = useCssModule();
const listClasses = computed(() => [styles.list, props.listClass]);
</script>

<template>
  <nav>
    <ul :class="[$style.list, listClasses]" role="list">
      <li v-for="{ id, to, title } in navItems" :key="id" :class="$style.item">
        <IrbisLink
          :to="to"
          :class="[$style.navLink, listItemClass]"
          :skin="LinkSkin.EMPTY"
        >
          {{ title }}</IrbisLink
        >
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" module>
.list {
  display: flex;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
}
.item {
  padding: 0 20px;
  margin: 0;
  @media (max-width: 900px) {
    padding: 0;
  }
}
.navLink {
  @include link();
  font-size: 15px;
  border-bottom: 2px solid transparent;
  &:hover {
    text-decoration: none;
    border-bottom: 2px solid $main;
  }

  @media (max-width: 900px) {
    display: block;
    border-bottom: none;
    width: 100%;
    padding: 7px 20px;

    &:hover {
      border-bottom: none;
      background-color: $main;
      color: white;
    }
  }
}
</style>
