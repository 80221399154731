import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { useAuthStore } from "@/store/useAuthStore/useAuthStore";
import { createHead } from "@unhead/vue";

const app = createApp(App);

axios.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 403) {
    router.push("/login");
  }
});

const metaHead = createHead();

app.use(createPinia());
app.use(router);
app.use(metaHead);

Promise.all([router.isReady(), useAuthStore().restoreSession()]).finally(() =>
  app.mount("#app"),
);
