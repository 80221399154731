import { createRouter, createWebHistory } from "vue-router";

const MainPage = () => import("@/pages/main-page/MainPage.vue");
const LoginPage = () => import("@/pages/login/LoginPage.vue");
const UniJudgePage = () => import("@/pages/uni-judge-page/UniJudgePage.vue");
const AdvancedJudgePage = () =>
  import("@/pages/advanced-judge-page/AdvancedJudgePage.vue");
const AdminPage = () => import("@/pages/admin/AdminPage.vue");

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to, _, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: MainPage,
    },
    {
      path: "/panel",
      name: "panel",
      component: AdminPage,
    },
    {
      path: "/service",
      redirect() {
        return { name: "universal-search-check" };
      },
      children: [
        {
          path: "/universal-search",
          name: "universal-search-check",
          component: UniJudgePage,
          meta: {
            queryType: "universal-search",
          },
        },
        {
          path: "/advanced-search",
          name: "advanced-search-check",
          component: AdvancedJudgePage,
          meta: {
            queryType: "advanced-search",
          },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
    },
  ],
});

export default router;
