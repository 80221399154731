<script setup lang="ts">
import AppHeader from "@/components/layout/AppHeader/AppHeader.vue";
import { useRoute } from "vue-router";
import AppCheckContent from "@/components/layout/AppCheckContent.vue";

const route = useRoute();
const isCheckPage = computed(() =>
  ["/universal-search", "/advanced-search"].some((item) =>
    route.path.includes(item),
  ),
);
</script>

<template>
  <div :class="$style.layout">
    <AppHeader />
    <AppCheckContent v-if="isCheckPage">
      <template #default>
        <slot />
      </template>
    </AppCheckContent>
    <div v-else :class="$style.content">
      <slot />
    </div>
    <div id="#modal" />
  </div>
</template>

<style lang="scss" module>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  height: 0;
  flex-grow: 1;
}
</style>
