<script setup lang="ts">
import { IrbisLink, LinkSkin } from "@ainform/irbis-ui-storybook";
import { type RouteLocationRaw, useRouter } from "vue-router";
import type { QueryType } from "@/shared/utils/types";

export interface ICheckSelectorItemProps {
  to: RouteLocationRaw;
  title: string;
  queryType: QueryType;
  selectedQueryType: QueryType;
}
const props = defineProps<ICheckSelectorItemProps>();
const styles = useCssModule();
const isActive = computed(() => props.queryType === props.selectedQueryType);

const buttonClasses = computed(() => [
  styles.checkButton,
  isActive.value && styles.selected,
]);
const router = useRouter();

const handleClick = (e: Event) => {
  e.preventDefault();
  router.push(props.to);
};
</script>

<template>
  <IrbisLink
    :skin="LinkSkin.EMPTY"
    :to="to"
    :class="[$style.optionButton, buttonClasses]"
    @click="handleClick"
  >
    {{ title }}
  </IrbisLink>
</template>

<style module lang="scss">
.optionButton {
  &:focus {
    outline: none;
  }
}
.checkButton {
  background: rgba(140, 138, 250, 0.1);
  border: none;
  border-radius: 4px;
  color: #42389d;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  font-size: 14px;
  margin-right: 6px;
  @include media($pageWidthXs) {
    padding: 8px 3px;
  }
  &:hover {
    background: rgba(140, 138, 250, 0.2);
    color: #42389d;
    cursor: pointer;
  }
}
.selected {
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 10px 16px;
  text-align: center;
  white-space: nowrap;
  background: #42389d;
  font-size: 14px;
  margin-right: 6px;
  @include media($pageWidthXs) {
    padding: 8px 12px;
  }
  &:hover {
    background: #42389d;
    color: #fff;
  }
}
</style>
