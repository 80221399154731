<script setup lang="ts">
import CloseButton from "@/shared/components/CloseButton.vue";
import Hamburger from "@/shared/components/Hamburger.vue";
import AppHeaderMobileDropdown from "./AppHeaderMobileDropdown/AppHeaderMobileDropdown.vue";
import { colors, DropDown } from "@ainform/irbis-ui-storybook";
import { useToggle } from "@vueuse/core";
import AppHeaderNav from "./AppHeaderNav.vue";
import type { INavLink } from "@/shared/utils/types";

interface IProps {
  isMobile: boolean;
  navItems: INavLink[];
}

defineProps<IProps>();

const [open, setOpen] = useToggle();
</script>

<template>
  <AppHeaderMobileDropdown
    v-if="isMobile"
    :open="open"
    :items="navItems"
    @toggle="setOpen"
  >
    <template #trigger>
      <Hamburger v-if="!open" :class="$style.button" color="#3b50a8" />
      <CloseButton
        v-else
        :class="$style.button"
        :color="colors.main"
        :hover-color="colors.main"
      />
    </template>
  </AppHeaderMobileDropdown>
  <DropDown
    v-else
    :visible="open"
    :offset="24"
    :content-class="$style.dropdown"
    placement="bottom-start"
    @close="setOpen(false)"
    @toggle="setOpen"
  >
    <template #trigger>
      <Hamburger :class="$style.button"></Hamburger>
    </template>
    <template #content>
      <AppHeaderNav :list-class="$style.list" :nav-items="navItems" />
    </template>
  </DropDown>
</template>

<style module lang="scss">
.button {
  width: 18px;
  height: 18px;
}

.dropdown {
  padding: 10px 0;
  border-radius: 10px;
}

.list {
  display: flex;
  flex-direction: column;
}
</style>
